<template>
  <LayoutThumbnailComponent
    linkName="participants-slug"
    :localizedData="attributes"
    :linkType="linkType"
    :class="$style.root"
    :image="coverImage"
  >
    <div :class="$style.information">
      {{ attributes?.collective_name || attributes?.name }}
    </div>

    <div :class="$style.note">
      {{ [years, placeBorn]?.filter(Boolean)?.join(', ') }}
    </div>
  </LayoutThumbnailComponent>

</template>

<script setup>
const props = defineProps({
  data: Object,
  linkType: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 'dynamic'].includes(value)
  }
})

const attributes = baseStrapiGetAttributes(props?.data)

const placeBorn = computed(() => i18nGetLocalizedString(attributes?.place_born))

const coverImage = computed(() => attributes?.cover_image?.data?.attributes)

const years = computed(() => {
  return [attributes?.year_born, attributes?.year_of_death].filter(Boolean)?.join(' – ')
})
</script>

<style module>
.root {
  --layout--thumbnail--aspect-ratio: 6 / 9;
}

.information {
  composes: font-size-small from global;

  margin-top: var(--unit--spacer);
}

.note {
  composes: font-size-x-small from global;
  color: var(--color--blue);
}
</style>
